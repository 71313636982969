import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

import loginLogo from "../../../assets/LogoNextgen.png";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import { adminLogin } from "../../../api-services/services";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
    password: Yup.string()
      .required("Password is required.")
      .min(5, "Password must be at least 5 characters."),
  });

  const handleLogin = async (values) => {
    const payload = values;
    setLoading(true);

    try {
      const res = await adminLogin(payload);

      const data = res.data;

      if (res.status === 200 && Object.values(data).length > 0) {
        localStorage.setItem("authUser", res.data.token);

        nav("/admin/dashboard");
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      // Handle any errors here
      if (error.response.status === 400) {
        toast.error("Invalid Password");
      }
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }

  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Container className="vh-100 w-100 d-flex align-items-center">
        <Row className="justify-content-center w-100">
          <Col md={8} lg={4} xl={4}>
            <Card className="mt-4 border-0">
              <CardBody className="p-5">
                <div className="text-center mt-2">
                  <img
                    src={loginLogo}
                    width={"200px"}
                    height={"100px"}
                    alt="loginLogo"
                  />
                  {/* <h5 className="company mt-4 mb-0 font-weight-bold">
                    NextGen Surveys
                  </h5> */}
                  <p className="text-muted ">Login to access your account</p>
                </div>
                <div className="p-2 mt-4">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(values) => {
                      handleLogin(values);
                    }}
                  >
                    {({ values, errors }) => (
                      <Form action="#">
                        <div className="mb-3">
                          <Label
                            htmlFor="email"
                            className="form-label input-label"
                          >
                            Email
                          </Label>
                          <Field
                            type="text"
                            className="form-control input-field"
                            id="email"
                            name="email"
                            placeholder="Enter username"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger error"
                          />
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label input-label"
                            htmlFor="password"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Field
                              type="password"
                              className="form-control pe-5 password-input input-field"
                              placeholder="Enter password"
                              id="password"
                              name="password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger error"
                            />
                          </div>
                        </div>

                        <div className="mt-4">
                          {!loading ? (
                            <Button
                              className="btn primary-btn w-100"
                              type="submit"
                            >
                              Login
                            </Button>
                          ) : (
                            <button
                              type="submit"
                              className="btn primary-btn w-100"
                              disabled
                            >
                              <Spinner
                                size="sm"
                                className="flex-shrink-0"
                              ></Spinner>
                            </button>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Login;
