import React from "react";
import Route from "./routes"

function App() {
  return (
    <React.Fragment>
      <div className="w-100">
          <Route />
      </div>

    </React.Fragment>
  );
}

export default App;
