import Dashboard from "../Admin/pages/Dashboard";
import Login from "../Admin/pages/login/Login";
import Reports from "../Admin/pages/Reports";


// Admin routes
export const nonAuthProtectedRoutes = [
    {path: "/", component: <Login/>},
]

export const authProtectedRoutes = [
    {path: "/admin/dashboard", component: <Dashboard/>},
    {path: "/admin/reports", component: <Reports/>},
]