import React, { useEffect, useState } from "react";
import DashboardStats from "../components/dashboard/DashboardStats";
import { Container, Spinner } from "reactstrap";
import Wrapper from "../components/common/Wrapper";
import TeamsTable from "../components/dashboard/TeamsTable";
import AddModal from "../components/common/AddModal";
import TeamListModal from "../components/common/TeamListModal";
import DateModal from "../components/common/DateModal";
import { ToastContainer, toast } from "react-toastify";
import { getAllTeams, getTeamByCode } from "../../api-services/services";
import { useNavigate } from "react-router-dom";

const dummyData = [
  {
    teamCode: "qwerty123",
    totalMembers: 150,
    completedSurveys: 87,
    pendingSurvey: 63,
  },
  {
    teamCode: "wq1234a",
    totalMembers: 110,
    completedSurveys: 17,
    pendingSurvey: 53,
  },
  {
    teamCode: "ad43dqaw",
    totalMembers: 120,
    completedSurveys: 17,
    pendingSurvey: 61,
  },
  {
    teamCode: "adsfa11z",
    totalMembers: 122,
    completedSurveys: 23,
    pendingSurvey: 33,
  },
  {
    teamCode: "adsfa11z",
    totalMembers: 122,
    completedSurveys: 23,
    pendingSurvey: 33,
  },
  {
    teamCode: "adsfa11z",
    totalMembers: 122,
    completedSurveys: 23,
    pendingSurvey: 33,
  },
  {
    teamCode: "adsfa11z",
    totalMembers: 122,
    completedSurveys: 23,
    pendingSurvey: 33,
  },
  {
    teamCode: "adsfa11z",
    totalMembers: 122,
    completedSurveys: 23,
    pendingSurvey: 33,
  },
  {
    teamCode: "adsfa11z",
    totalMembers: 122,
    completedSurveys: 23,
    pendingSurvey: 33,
  },
  {
    teamCode: "adsfa11z",
    totalMembers: 122,
    completedSurveys: 23,
    pendingSurvey: 33,
  },
  {
    teamCode: "adsfa11z",
    totalMembers: 122,
    completedSurveys: 23,
    pendingSurvey: 33,
  },
];

const Dashboard = () => {
  const [teamsList, setTeamsList] = useState(dummyData);

  const [teamModal, setTeamModal] = useState(false);
  const [memberModal, setMemberModal] = useState(false);
  const [teamListModal, setTeamListModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teamLoading, setTeamLoading] = useState(false);
  const [call, setCall] = useState(false);
  const [teamList, setTeamList] = useState(null);
  const [dashboardStats, setDashboardStats] = useState(null);
  const [teamCode, setTeamCode] = useState(null);
  const [memberList, setMemberList] = useState(null);
  // const [loading, setLoading] = useState(false);

  const nav = useNavigate();
  const toggleTeamModal = () => setTeamModal(!teamModal);
  const toggleMemberModal = () => setMemberModal(!memberModal);
  const toggleTeamListModal = () => {
    if (teamListModal === true) {
      setTeamCode(null);
      getTeamListing();
    }
    setTeamListModal(!teamListModal);
  };
  const toggleDateModal = () => setDateModal(!dateModal);

  useEffect(() => {
    getTeamListing();
  }, []);

  const getTeamListing = async () => {
    setLoading(true);
    try {
      const res = await getAllTeams();
      const data = res.data;
      if (res.status === 200 && Object.values(data).length > 0) {
        setTeamList(res.data?.response?.surveyStats);
        setDashboardStats(res.data?.response);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Unauhtorized: Login again");
        nav("/");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (teamCode) {
      console.log("first");
      getTeamDetails(teamCode);
    }
    return () => {
      setMemberList(null);
    };
  }, [teamCode]);

  useEffect(() => {
    if (memberModal === false && teamCode) {
      console.log("second");

      getTeamDetails(teamCode);
    }
  }, [memberModal]);

  useEffect(() => {
    if (call === true) {
      console.log("third");

      getTeamDetails(teamCode);
      setCall(false);
    }
  }, [call]);

  // GET TEAM DETAILS
  const getTeamDetails = async (teamCode) => {
    setTeamLoading(true);
    try {
      const res = await getTeamByCode(teamCode);
      const data = res.data?.team;
      if (res.status === 200 && Object.values(data).length > 0) {
        setMemberList(res.data?.team?.members);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      if (error.response.status === 401) {
        nav("/", {message: "Unauhtorized: Login again"});
      }
    } finally {
      setTeamLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />

      <DashboardStats
        dashboardStats={dashboardStats}
        toggleDateModal={toggleDateModal}
      />
      <TeamsTable
        loading={loading}
        data={teamList}
        toggle={toggleTeamModal}
        toggleTeamListModal={toggleTeamListModal}
        setTeamCode={setTeamCode}
      />

      <AddModal
        modal={teamModal}
        toggle={toggleTeamModal}
        getTeamListing={getTeamListing}
      />
      <AddModal
        modal={memberModal}
        toggle={toggleMemberModal}
        teamCode={teamCode}
        getTeamListing={getTeamListing}
      />
      <TeamListModal
        modal={teamListModal}
        toggle={toggleTeamListModal}
        openAddModal={toggleMemberModal}
        teamCode={teamCode}
        loading={teamLoading}
        setCall={setCall}
        memberList={memberList}
      />
      <DateModal modal={dateModal} toggle={toggleDateModal} />
    </>
  );
};

export default Dashboard;
