import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { LuCopy } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import { deleteMember } from "../../../api-services/services";
import { toast } from "react-toastify";
import CustomSpinner, { BtnSpinner } from "../common/Spinner";
import { handleCopyEmails } from "../../../utils/conversions";

const TeamListModal = ({ modal, toggle, teamCode, openAddModal, loading, memberList, setCall }) => {
  console.log("🚀 ~ file: TeamListModal.jsx:20 ~ TeamListModal ~ memberList:", memberList)
  
  const nav = useNavigate();
  const [btnLoading, setBtnLoading] = useState({
    id: "",
    loading: false,
  });


  // DELETE TEAM MEMBER
  const deleteTeamMember = async ({ userEmail, id }) => {
    setBtnLoading({
      id: id,
      loading: true,
    });
    const payload = {
      code: teamCode,
      email: userEmail,
    };

    try {
      const res = await deleteMember(payload);
      if (res?.success === true && res?.status === 200) {

        toast.info(res.message);
        setCall(true);
        // getTeamDetails(teamCode);
      } else if (res.status === 404 && res.success === false) {
        toast.error(res.message);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Unauhtorized: Login again");
        nav("/");
      }
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      {/* <ModalHeader toggle={toggle} className="border-0"></ModalHeader> */}
      <ModalBody>
        <div className="d-flex justify-content-between mb-2">
          <div>
            <p className="modal-title">Members</p>
            {teamCode && <span className="sub-title">{teamCode}</span>}
          </div>
          <div className="d-flex justify-content-between align-items-center gap-3">
            <LuCopy
              className=""
              onClick={() => handleCopyEmails(memberList)}
              style={{
                color: "rgba(10, 179, 156, 1)",
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
            <Button color="btn success-btn" onClick={openAddModal}>
              Add Member
            </Button>{" "}
          </div>
        </div>
        {!loading ? (
          <div className="list">
            {memberList?.length > 0 ? (
              memberList?.map((item, idx) => (
                <div
                  key={idx}
                  className={`d-flex align-items-center justify-content-between list-item ${
                    idx === memberList?.length - 1 ? "border-0" : ""
                  }`}
                >
                  <p className="p-0 m-0 input-label">{item?.userEmail}</p>
                  {btnLoading?.loading && btnLoading?.id === item?.id ? (
                    <button className="" onClick={() => deleteTeamMember(item)}>
                      <BtnSpinner type={"danger"} />
                    </button>
                  ) : (
                    <button className="" onClick={() => deleteTeamMember(item)}>
                      <RiDeleteBin5Line />
                    </button>
                  )}
                </div>
              ))
            ) : (
              <p
                className="mt-4  fw-light text-danger"
                style={{ fontSize: "14px" }}
              >
                No data to show.
              </p>
            )}
          </div>
        ) : (
          <CustomSpinner />
        )}
      </ModalBody>
    </Modal>
  );
};

export default TeamListModal;
