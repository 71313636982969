import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "reactstrap";

const Chart = ({ series, colors , title}) => {
  const options = {
    chart: {
      type: "bar",
      height: 425,
      width: '100%'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Q.1",
        "Q.2",
        "Q.3",
        "Q.4",
        "Q.5",
        "Q.6",
        "Q.7",
        "Q.8",
        "Q.9",
        "Q.10",
        "Q.11",
        "Q.12",
        "Q.13",
        "Q.14",
        "Q.15",
        "Q.16",
        "Q.17",
        "Q.18",
        "Q.19",
        "Q.20",
        "Q.21",
        "Q.22",
        "Q.23",
        "Q.24",
        "Q.25",
        "Q.26",
        "Q.27",
        "Q.28",
        "Q.29",
        "Q.30",
        // "Q.31",
        // "Q.32",
        // "Q.33",
        // "Q.34",
        // "Q.35",
        // "Q.36",
        // "Q.37",
        // "Q.38",
        // "Q.39",
        // "Q.41",
        // "Q.42",
        // "Q.43",
        // "Q.44",
        // "Q.45",
        // "Q.46",
        // "Q.47",
        // "Q.48",
        // "Q.49",
        // "Q.50",
        // "Q.51",
        // "Q.52",
        // "Q.53",
        // "Q.54",
        // "Q.55",
        // "Q.56",
        // "Q.57",
        // "Q.58",
        // "Q.59",
        // "Q.60",
      ],
    },
    yaxis: {
      title: {
        text: `${title} (Answers)`,
      },
    },
    fill: {
      opacity: 1,
      colors: colors,
    },
    
    tooltip: {
      y: {
        formatter: function (val) {
          return  val ;
        },
      },
    },
    legend: {
        show: true,
        horizontalAlign: "left",
        
        markers: {
            fillColors: colors,
        }

    }
  };

  return (
    <div className="pb-4 w-100">
      <Card className="border-0 p-3">
        <ReactApexChart
          options={options}
          series={series || []}
          type="bar"
          height={425}
        />
      </Card>
    </div>
  );
};

export default Chart;
