import { Field } from "formik";
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { addTeamMember, createTeam } from "../../../api-services/services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddModal = ({ modal, toggle, teamCode, getTeamListing }) => {
  const nav = useNavigate();
  const [inputValue, setInputValue] = useState({});
  const [loading, setLoading] = useState(false);
  const today = new Date().toISOString().split('.')[0];
  console.log("🚀 ~ file: AddModal.jsx:22 ~ AddModal ~ today:", today)
  const handleInput = (e) => {
    if (e.target.name === "teamCode") {
      setInputValue((prev) => ({ ...prev, name: e.target.value }));
    } else if (e.target.name === "email") {
      setInputValue({ email: e.target.value });
    } else if (e.target.name === "deadline") {
      setInputValue((prev) => ({ ...prev, deadline: e.target.value }));
    }
  };

  const handleAdd = async () => {
    setLoading(true);
    if (teamCode) {
      const payload = {
        code: teamCode,
        emails: [inputValue?.email],
      };

      try {
        const res = await addTeamMember(payload);
        const data = res.data;
        if (res.status === 200 && Object.values(data).length > 0) {
          toast.info(res.message);
          // getTeamListing();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        if (error.response.status === 401) {
          toast.error("Unauhtorized: Login again");
          nav("/");
        } else {
          toast.error(error.response.message);
        }
      } finally {
        toggle();
        setLoading(false);
      }
    } else {
      const payload = inputValue;

      try {
        const res = await createTeam(payload);

        const data = res.data;

        if (res.status === 200 && Object.values(data).length > 0) {
          toast.info(res.message);
          getTeamListing();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.response.message);

        console.error("Error:", error);
      } finally {
        toggle();
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <div className="mb-3">
            <p className="modal-title mb-0">
              Add {teamCode ? "Member" : "Team"}
            </p>
            {teamCode && <span className="sub-title">{teamCode}</span>}
          </div>
          {teamCode ? (
            <div className="mb-3">
              <Label htmlFor="email" className="form-label input-label">
                Email Address
              </Label>
              <Input
                type="text"
                className="form-control input-field"
                id="email"
                name="email"
                placeholder="Enter email"
                onChange={(e) => handleInput(e)}
              />
            </div>
          ) : (
            <>
              <div className="mb-3">
                <Label htmlFor="teamCode" className="form-label input-field">
                  Team Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="teamCode"
                  name="teamCode"
                  onChange={(e) => handleInput(e)}
                  placeholder="Enter team name"
                />
              </div>
              <div className="mb-3">
                <Label htmlFor="deadline" className="form-label input-field">
                  Deadline
                </Label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="deadline"
                  name="deadline"
                  min={`${today}`}
                  onChange={(e) => handleInput(e)}
                />
              </div>
            </>
          )}
          <div className="d-flex justify-content-end gap-3 pt-2">
            <Button className="secondary-btn" onClick={toggle}>
              Cancel
            </Button>
            {!loading ? (
              <Button
                color="btn primary-btn"
                disabled={
                  (teamCode && !inputValue?.email) ||
                  (!inputValue?.name && !teamCode)
                    ? true
                    : false
                }
                onClick={handleAdd}
              >
                {teamCode ? "Add Member" : "Create Team"}
              </Button>
            ) : (
              <button type="submit" className="btn primary-btn" disabled>
                <Spinner size="sm" className="flex-shrink-0"></Spinner>
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddModal;
