import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

export function formatDateTime(iso8601DateTime) {
  const date = new Date(iso8601DateTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const handleCopyEmails = (emails) => {
  const emailList = emails.map((item) => item?.userEmail);
  if(emailList.length > 0){
    copy(emailList);
    toast.success("Emails copied to clipboard.");
  } else {
    toast.error("No data to copy!.");
  }
};
