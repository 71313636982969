import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import Sidebar from "../Admin/components/common/Sidebar";
import Navbar from "../Admin/components/common/Navbar";
import Wrapper from "../Admin/components/common/Wrapper";
import { Col } from "reactstrap";

const AuthLayout = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("authUser"));


  if (!authToken) {
    return <Navigate to={"/"} />;
  }

  return (
    <React.Fragment>
      <div className="d-flex w-100">
        <Col className="col-2">
        <Sidebar />
        </Col>
        <div className="col-10 ">
          <Navbar />
          <Wrapper>{children}</Wrapper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthLayout;
