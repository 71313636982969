export const urls = {
    LOGIN: "/api/admin/login",
    CREATE_TEAM: "/api/team/create",
    GET_ALL_TEAMS: "/api/team/get-all",
    GET_DASHBOARD_STATS: "/api/survey/stats",
    GET_TEAM_BY_ID: "/api/team",
    DELETE_TEAM_MEMBER: "/api/team-members/delete",
    ADD_TEAM_MEMBER: "/api/team-members/add",
    GET_AVERAGE: "/api/survey/average",
    GET_MEDIAN: "/api/survey/median",
  
  };
  