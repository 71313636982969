import React, { useEffect, useState } from "react";
import CombinedChart from "../components/reports/CombinedChart";
import AverageChart from "../components/reports/AverageChart";
import Chart from "../components/reports/Chart";
import { getAllTeams, getScore } from "../../api-services/services";
import { urls } from "../../api-services/apiEndpoint";
import { Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [averageStat, setAverageStat] = useState([]);

  const [medianStat, setMedianStat] = useState([]);
  const [presentAM, setPresentAM] = useState([]);
  const [futureAM, setFutureAM] = useState([]);

  useEffect(() => {
    if (selectedTeam) {
      getSurveyAverage(selectedTeam);
      getSurveyMedian(selectedTeam);
    }
  }, [selectedTeam]);

  // GET SURVEY AVERAGE
  const getSurveyAverage = async (selectedTeam) => {
    try {
      const res = await getScore(urls.GET_AVERAGE, selectedTeam);
      if (res?.status === 200) {
        const data = [
          {
            name: "Present",
            data: res.data.response?.presentAnswersAverages || [],
          },
          {
            name: "Future",
            data: res.data.response?.futureAnswersAverages || [],
          },
        ];

        setAverageStat(data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Unauhtorized: Login again");
        nav("/");
      }
    } finally {
      setLoading(false);
    }
  };

  // GET SURVEY MEDIAN
  const getSurveyMedian = async (selectedTeam) => {
    try {
      const res = await getScore(urls.GET_MEDIAN, selectedTeam);

      if (res?.status === 200) {
        const data = [
          {
            name: "Present",
            data: res.data.response?.presentAnswersMedians || [],
          },
          {
            name: "Future",
            data: res.data.response?.futureAnswersMedians || [],
          },
        ];
        setMedianStat(data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        nav("/");
        toast.error("Unauhtorized: Login again");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // PRESENT AVERAGE AND MEDIAN
    const presentAM = [
      {
        name: "Average",
        data: averageStat?.[0]?.data,
      },
      {
        name: "Median",
        data: medianStat?.[0]?.data,
      },
    ];
    setPresentAM(presentAM);

    // FUTURE AVERAGE AND MEDIAN
    const futureAM = [
      {
        name: "Average",
        data: averageStat?.[1]?.data,
      },
      {
        name: "Median",
        data: medianStat?.[1]?.data,
      },
    ];

    setFutureAM(futureAM);
  }, [averageStat, medianStat]);

  useEffect(() => {
    getTeamListing();
  }, []);

  const handleInput = (e) => {
    setSelectedTeam(e.target.value);
  };

  const getTeamListing = async () => {
    setLoading(true);
    try {
      const res = await getAllTeams();
      const data = res.data;
      if (res.status === 200 && Object.values(data).length > 0) {
        setTeamList(res.data?.response?.surveyStats);
        setSelectedTeam(res.data?.response?.surveyStats?.[0].teamId);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Unauhtorized: Login again");
        nav("/");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="w-100">
        <div className="mb-3">
          <Label htmlFor="email" className="form-label input-label">
            Select Team Code
          </Label>
          <Input
            type="select"
            className="form-control input-field"
            id="teamCode"
            name="teamCode"
            placeholder="Enter team code"
            onChange={(e) => handleInput(e)}
          >
            {teamList.map((team) => (
              <option>{team.teamId}</option>
            ))}
          </Input>
        </div>

        {/*  PRESENT AND FURUTRE AVERAGE ANALYTICS */}
        <Chart
          series={presentAM}
          title={"Present"}
          colors={["#FFC84B", "#6ADA7D"]}
        />
        {/*  PRESENT AND FURUTRE MEDIAN ANALYTICS */}
        <Chart
          series={futureAM}
          title={"Future"}
          colors={["#FFC84B", "#6ADA7D"]}
        />

        <Chart
          series={averageStat}
          title={"Average"}
          colors={["#020F29", "#5088A9"]}
        />
        <Chart
          series={medianStat}
          title={"Median"}
          colors={["#020F29", "#5088A9"]}
        />
      </div>
    </React.Fragment>
  );
};

export default Reports;
