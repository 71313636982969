import React from "react";
import { Spinner } from "reactstrap";

const CustomSpinner = () => {
  return (
    <div className="mt-5 mb-4"  >
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spinner color="primary" className="me-2" />
      </div>
    </div>
  );
};


export const BtnSpinner= ({type}) => (
  <Spinner color={type} size="sm" className="" />
)

export default CustomSpinner;
