import axios from "axios";
import { urls } from "./apiEndpoint";

// const SERVER_URL = "http://93.188.167.86:3500";
const SERVER_URL =  "https://api.nextgen.how"; // "http://127.0.0.1:5000";

export const authToken = () => {
  return localStorage.getItem("authUser");
};

// USE THIS
// const apiInstance = axios.create({
//   baseURL: "http://localhost:5000", // Set your base API URL
//   headers: {
//     Authorization: `Bearer ${authToken}`,
//   },
// });

export const adminLogin = async (payload) => {
  try {
    console.log(
      "🚀 ~ file: services.js:5 ~ SERVER_URL:",
      process.env.REACT_APP_API_KEY
    );
    const response = await axios.post(`${SERVER_URL}${urls.LOGIN}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTeam = async (payload) => {
  try {
    const response = await axios.post(
      `${SERVER_URL}${urls.CREATE_TEAM}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authUser")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllTeams = async () => {
  try {
    const response = await axios.get(
      `${SERVER_URL}${urls.GET_DASHBOARD_STATS}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authUser")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTeamByCode = async (teamCode) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}${urls.GET_TEAM_BY_ID}/${teamCode}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authUser")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMember = async (payload) => {
  try {
    const response = await axios.post(
      `${SERVER_URL}${urls.DELETE_TEAM_MEMBER}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authUser")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addTeamMember = async (payload) => {
  try {
    const response = await axios.post(
      `${SERVER_URL}${urls.ADD_TEAM_MEMBER}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authUser")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getScore = async (url, selectedTeam) => {
  try {
    const response = await axios.get(`${SERVER_URL}${url}/${selectedTeam}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authUser")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
